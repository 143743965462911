/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import "../scss/style.scss"
import { CSSPlugin, gsap } from "gsap"
import { Helmet } from "react-helmet"

gsap.registerPlugin(CSSPlugin)

class Layout extends React.Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {}

  render() {
    const { children, bodyTheme } = this.props

    return (
      <>
        <Helmet
          bodyAttributes={{
            class: bodyTheme,
          }}
        />
        <main>{children}</main>
      </>
    )
  }
}

export default Layout
